/* purple 7279ff 5f64d4 696edd */
/* orange eb9c68 b87c54 f4c5a6 */

:root {
  color-scheme: only light;
  --primary-color: #7279ff;
  --primary-color-dark: #5f64d4;
  --primary-color-light: #868af0;
  --text-color-primary: #1E90FF;
  --text-color-secondary: #1E90FF;
  --secondary-color: #FFB6C1;
  --secondary-color-dark: #FFFACD;
  --red-color: #ff5252;
  --red-color-dark: #e04848;
  --cool-gray: #f0f0f0;
  --cool-gray-hover: #e0e0e0;
  --dark-grey: #aaa;
  --charcoal: #36454F;
  --background-color: #fff;
  --text-color: #000;

  /* Light theme colors */
--background-color-light: #ffffff;
--text-color-light: #000000;
--cool-gray-light: #f0f0f0;
--cool-gray-light-hover: #e0e0e0;

/* Dark theme colors */
--background-color-dark: #121212;
--text-color-dark: #ffffff;
--cool-gray-dark: #333;
--cool-gray-dark-hover: #444;

}

/* Light mode */
body.light-mode {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --cool-gray: var(--cool-gray-light);
  --cool-gray-hover: var(--cool-gray-light-hover);

}

/* Dark mode */
body.dark-mode {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --cool-gray: var(--cool-gray-dark);
  --cool-gray-hover: var(--cool-gray-dark-hover);
}
