@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.toast-custom{
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  text-align: center;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0);
  }
}

.floating1 {
  animation: floating 3s ease-in-out infinite;
  animation-delay: 0s; /* Adjust the delay time as needed */
}
.floating2 {
  animation: floating 3s ease-in-out infinite;
  animation-delay: 1s; /* Adjust the delay time as needed */
}
.floating3 {
  animation: floating 3s ease-in-out infinite;
  animation-delay: 2s; /* Adjust the delay time as needed */
}


.greyed-out {
  color: grey; /* Change text color to grey */
  opacity: 0.5; /* Optional: Reduce opacity for a more 'disabled' look */
}

.greyed-out .fa, /* For FontAwesome 4 */
.greyed-out .fas, /* For FontAwesome 5 solid */
.greyed-out .far, /* For FontAwesome 5 regular */
.greyed-out .fal, /* For FontAwesome 5 light */
.greyed-out .fab { /* For FontAwesome 5 brands */
  color: grey !important; /* Change FontAwesome icon color to grey */
}

div:focus,
div:active,
div:focus-visible,
div:focus-within {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: none;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.slide-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.slide-exit {
  opacity: 0;
  transform: translateX(0);
}
.slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

.slide-back-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-back-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.slide-back-exit {
  opacity: 0;
  transform: translateX(0);
}
.slide-back-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}
*, *::before, *::after {
  box-sizing: inherit;
}


html {
  /* this makes sure the padding and the border is included in the box sizing */
  @media (min-width: 768px) {
    overflow-y: hidden;
  }
}

html body {
  @media (min-width: 768px) {
    padding: 0;
    margin: 0;
    overflow-y: inherit;
  }
}

@keyframes vibrate {
  0% { transform: translateX(0); }
  25% { transform: translateX(5px); }
  50% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}

.vibrate {
  animation: vibrate 0.3s linear;
}
